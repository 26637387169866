$(document).ready(function () {

    // TODO: Obtener una referencia de la tabla, obtener una referencia del campo oculto
    var parseTable = function ($table) {
        // TODO!
        var rows = {};
        var $rows = $table.find('tbody tr');

        $rows.each(function(i){
            var header = $(this).find('th').text();
            var $cols = $(this).find('td');
            var cols = [];

            $cols.each(function() {
                var $col = $(this);

                if ($col.attr('name') === 'buttons') {
                    return true;
                }

                var text = $col.text();
                if (text.length===0) {
                    cols.push(null);
                } else {
                    cols.push(Number(text));
                }
            });

            rows[header] = cols;
        });



        return rows;
    };


    var updateObject = function () {

        var referencia = this.attr('data-table-id');

        var $input = $('input[data-matriz-object="' + referencia + '"]');


        var data = JSON.stringify(parseTable(this));

        $input.val(data);
    };


    var init = function () {
        var $table = $('table[data-table-type="matriz"]');

        $table.SetEditable(
            {
                onEdit: updateObject.bind($table)
            }
        );

    };


    init();
});