AluminiosVallirana.tables = {

    tablesRef: {},

    init: function (tables) {
        this.tables = tables;

        for (var i = 0; i < tables.context.length; i++) {
            var id = tables.context[i].sInstance.replace(/_\d*$/i, '');
            this.tablesRef[id] = i;
        }

        // console.log("Tablas registradas:", this.tables);
        // console.log("Tablas registradas REF:", this.tablesRef);
    },

    updateTabHeader: function (idStartsWith, diff) {
        var $item = $('a[href^=#tab_' + idStartsWith + ']'),
            pattern = /(^.*[^0-9])([0-9]*)/i,
            result = pattern.exec($item.text());

        if ($item.length > 0) {
            $item.text(result[1] + (parseInt(result[2]) + diff));
        }

    },

    removeIcon: function ($icons) {
        $icons.each(function () {
            // console.log("removing from:", this);
            $(this).css('display', 'none');
        })
    },

    showIcon: function ($icons) {
        $icons.each(function () {
            // console.log("adding to:", this);
            $(this).css('display', 'inline');
        })
    },

    moveRow: function (source, destination, id, pref) {
        if (this.tablesRef[pref + source] === undefined) {
            // console.log("No existe la tabla:", pref + source);
            return;
        }

        var $table1 = this.tables.table(this.tablesRef[pref + source]);
        var row = $table1.row('[data-item-id=' + id + ']');
        var rowNode = row.node();

        // console.log(pref, source)
        // console.log(this.tablesRef[pref + source]);
        // console.log($table1);


        row.remove().draw(false);


        this.updateTabHeader(source, -1);

        if (this.tablesRef[pref + destination] === undefined) {
            // console.log("No existe la tabla:", pref + destination);
            return;
        }

        var $table2 = this.tables.table(this.tablesRef[pref + destination]);

        // console.log($table2.row, rowNode);

        $table2
            .row.add(rowNode)
            .draw();


        this.updateTabHeader(destination, +1);
    },

    deleteRow: function (table, id, pref) {

        if (this.tablesRef[pref + table] === undefined) {
            console.log("No existe la tabla:", pref + table);
            return;
        }

        var $table1 = this.tables.table(this.tablesRef[pref + table]);

        var found = $table1.row('[data-item-id=' + id + ']').length > 0;

        if (found) {
            // $table1.row('[data-item-id=' + id + ']').remove().draw();
            $table1.row('[data-item-id=' + id + ']').remove();
            // redibujar no funciona, la eliminamos directamente
            $('[data-item-id=' + id + ']').remove();
            this.updateTabHeader(table, -1);
        }else {
            console.log("No se ha encontrarod el item con id:", id);
        }

    },

    updateRow: function (table, id, pref, newData) {

        if (this.tablesRef[pref + table] === undefined) {
            console.log("No existe la tabla:", pref + table);
            return;
        }

        var $table1 = this.tables.table(this.tablesRef[pref + table]);

        var oldData = $table1.row('[data-item-id=' + id + ']').data();

        for (var i = 0; i < newData.length; i++) {
            oldData[i] = newData[i];
        }

        var dataQueryRow = $table1.row('[data-item-id=' + id + ']');

        dataQueryRow.data(oldData).invalidate();

        $(dataQueryRow.node()).find('[data-ajax-method]').ajaxButton({dispatcher: AluminiosVallirana.dispatcher});

    },

    addRow: function (table, id, pref, data, extraClass) {

        if (this.tablesRef[pref + table] === undefined) {
            console.log("No existe la tabla:", pref + table);
            return;
        }

        var $table1 = this.tables.table(this.tablesRef[pref + table]);

        var dataQueryRow = $table1.row.add(data).draw();

        $(dataQueryRow.node()).find('[data-ajax-method]').ajaxButton({dispatcher: AluminiosVallirana.dispatcher});

        jQuery(dataQueryRow.node()).addClass(extraClass);

        this.updateTabHeader(table, 1);

    }


};

$(document).ready(function () {


    // Buscamos todos los elementos con data-counter-id
    var $dataCounters = $('[data-counter-id]');


    var extractDataCounter = function ($counter) {
            return {
                id: $counter.attr('data-counter-id'),
                min: $counter.attr('data-counter-min'),
                max: $counter.attr('data-counter-max'),
                expected: $counter.attr('data-counter-expected'),
                value: $counter.attr('data-counter-value'),
                type: $counter.attr('data-counter-type'),
                $node: $counter,
                $textSrc: $($counter).parent().find('input, textarea')
            };
        },

        countWords = function (text) {

            return text ? text.match(/\S+/g).length : 0;
        },

        countChars = function (text) {
            return text ? text.length : 0;
        },

        updateCounters = function (data) {
            var newLabel,
                count,
                text = data.$textSrc.val();

            //console.log("Updating", data);

            switch (data.type) {
                case "char":
                    count = countChars(text);
                    newLabel = count + "/" + (data.max || data.expected) + " carácteres";
                    break;

                //case "word":
                //    count = countWords(text);
                //    newLabel = count + "/" + (data.max || data.expected) + " palabras";
                //    break;

                default:
                    console.error("Type no valido");
            }

            data.value = count;
            data.$node.text(newLabel);
            data.$node.attr('data-count-value', count);

            updateColors(data);

            //console.log(newLabel);

        },

        updateColors = function (data) {

            //console.log(data);
            if (data.value < data.min) {

                data.$node.removeClass('warning');
                data.$node.addClass('danger');

            } else if (data.max && data.value > data.max) {

                data.$node.addClass('danger');

            } else if (data.expected && data.value < data.expected) {
                data.$node.removeClass('danger');
                data.$node.addClass('warning');
            } else if (data.value < data.max * 0.8) {
                // Calculamos un 80% del espacio como mínimo para salir del warning

                data.$node.removeClass('danger');
                data.$node.addClass('warning');

            } else {

                data.$node.removeClass('danger');
                data.$node.removeClass('warning');
            }
        }, removePattern = function () {
            var pattern = '',
                prePattern = '\\b(',
                postPattern = ')\\b';

            words = ['la', 'las', 'lo', 'los', 'de', 'del', 'el', 'al', 'le', 'les', 'y', 'a'];

            for (var i = 0, len = words.length; i < len; i++) {
                if (i !== 0) {
                    pattern += '|';
                }
                pattern += words[i];
            }

            return new RegExp(prePattern + pattern + postPattern, 'g');

        }, removeAccents = function (text) {

            if (!text) {
                return;
            }
            var defaultDiacriticsRemovalMap = [
                {'base': 'a', 'letters': 'àá'},
                {'base': 'e', 'letters': 'èé'},
                {'base': 'i', 'letters': 'íï'},
                {'base': 'o', 'letters': 'òó'},
                {'base': 'u', 'letters': 'úü'},
                {'base': 'n', 'letters': 'ñ'},
                {'base': 'c', 'letters': 'ç'}
            ];

            for (var i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
                //console.log("replace: ", defaultDiacriticsRemovalMap[i]);


                text = text.replace(new RegExp("[" + defaultDiacriticsRemovalMap[i].letters + "]", "g"), defaultDiacriticsRemovalMap[i].base);
                //text = text.replace(defaultDiacriticsRemovalMap[i].letters, defaultDiacriticsRemovalMap[i].base);
            }
            //

            return text.toString();

        }, updateSlug = function (text, $slug) {

            var normalizedText = removeAccents(text.toLowerCase())
                .replace(removePattern(), ' ', 'g')  // Reemplazamos las palabras excluidas
                //.replace(removePattern(), ' ', 'g')// Reemplazamos de nuevo (FIX: solo se captura el primer espacio por eso si hay dos consecutivas la siguiente se ignora)
                .replace(/\s+|\s+$/g, '-')      // Replace spaces with -;
                .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
                .replace(/\-\-+/g, '-')         // Replace multiple - with single -
                .replace(/^-+/, '')             // Trim - from start of text
                .replace(/-+$/, '');            // Trim - from end of text


            //console.log('slug', $slug);
            //console.log('text', normalizedText);

            $slug.val(normalizedText);


        }, initDataCounters = function () {
            // Los recorremos y les añadimos un listener para controlar: keyup, cut, paste AL PARENT
            $dataCounters.each(function (index) {
                var $dataCounter = $(this);

                //console.log("id:" + $dataCounter.attr('data-counter-id'));

                var data = extractDataCounter($(this));

                // Init
                updateCounters(data);


                //$(this).parent().on("cut paste", function () {
                $(this).parent().on("change keypress keyup cut paste", function () {

                    updateCounters(data);

                });
            });
        }, initSlug = function () {
            var $slug = $('[data-slug]'),
                $title = $('[data-title]');


            //updateSlug($title.val(), $slug); // En este caso no se inicializa, si hay un valor distinto se debe conservar

            $title.on("change keypress keyup cut paste", function () {
                updateSlug($title.val(), $slug);
            });


        },
        initVideo = function () {
            var $input = $('#video-form input[type="text"]'),
                $button = $('#video-form button');


            $button.click(function () {
                var id = parseInputVideo($input.val());
                $input.val(id);

                createVideo(id);
            })
        },

        parseInputVideo = function (input) {
            var pattern = /v=(?:(?!&).)*|v=(.*)$/,
                matches = pattern.exec(input);

            //console.log(pattern.exec(input));

            return matches[0].replace('v=', '');
        },

        createVideo = function (id) {
            var node;

            if ($('#video-gallery').length) {
                replaceVideo(id);

            } else {

                node = '<div class="embed-responsive embed-responsive-16by9" id="video-gallery">'
                    + '<iframe class="embed-responsive.item" src="https://www.youtube.com/embed/' + id
                    + '" data-video-id="' + id + '" frameborder="0" allowfullscreen></iframe>'
                    + '</div>';

                $('#video-form').append(node);

            }


        },
        replaceVideo = function (id) {
            var node = '<iframe class="embed-responsive.item" src="https://www.youtube.com/embed/' + id
                + '" data-video-id="' + id + '" frameborder="0" allowfullscreen></iframe>'

            $('#video-gallery iframe').replaceWith(node)

        },
        updateTitles = function ($node, text) {

            $node.val(text);
            $node.trigger('change');
            //console.log("text: ", text);

        },
        initMasterTitleSync = function () {
            var $masterTitle = $('[data-title-master]'),
                $bindedTitles = $('[data-title-echo]');


            //console.log("masterTitle:", $masterTitle);
            $masterTitle.on("change keypress keyup cut paste", function () {

                $bindedTitles.each(function () {
                    //console.log("canvis");
                    updateTitles($(this), $masterTitle.val());

                });


            });

        },

        initRichTextEditors = function () {
            var $editors = $('[data-rich-text]');


            $editors.each(
                function () {
                    tinymce.addI18n('es', {
                        "Cut": "Cortar",
                        "Heading 5": "Encabezado 5",
                        "Header 2": "Encabezado 2 ",
                        "Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Tu navegador no soporta acceso directo al portapapeles. Por favor usa las teclas Crtl+X\/C\/V de tu teclado",
                        "Heading 4": "Encabezado 4",
                        "Div": "Capa",
                        "Heading 2": "Encabezado 2",
                        "Paste": "Pegar",
                        "Close": "Cerrar",
                        "Font Family": "Familia de fuentes",
                        "Pre": "Pre",
                        "Align right": "Alinear a la derecha",
                        "New document": "Nuevo documento",
                        "Blockquote": "Bloque de cita",
                        "Numbered list": "Lista numerada",
                        "Heading 1": "Encabezado 1",
                        "Headings": "Encabezados",
                        "Increase indent": "Incrementar sangr\u00eda",
                        "Formats": "Formatos",
                        "Headers": "Encabezados",
                        "Select all": "Seleccionar todo",
                        "Header 3": "Encabezado 3",
                        "Blocks": "Bloques",
                        "Undo": "Deshacer",
                        "Strikethrough": "Tachado",
                        "Bullet list": "Lista de vi\u00f1etas",
                        "Header 1": "Encabezado 1",
                        "Superscript": "Super\u00edndice",
                        "Clear formatting": "Limpiar formato",
                        "Font Sizes": "Tama\u00f1os de fuente",
                        "Subscript": "Sub\u00edndice",
                        "Header 6": "Encabezado 6",
                        "Redo": "Rehacer",
                        "Paragraph": "P\u00e1rrafo",
                        "Ok": "Ok",
                        "Bold": "Negrita",
                        "Code": "C\u00f3digo",
                        "Italic": "It\u00e1lica",
                        "Align center": "Alinear al centro",
                        "Header 5": "Encabezado 5 ",
                        "Heading 6": "Encabezado 6",
                        "Heading 3": "Encabezado 3",
                        "Decrease indent": "Disminuir sangr\u00eda",
                        "Header 4": "Encabezado 4",
                        "Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Pegar est\u00e1 ahora en modo de texto plano. El contenido se pegar\u00e1 como texto plano hasta que desactive esta opci\u00f3n.",
                        "Underline": "Subrayado",
                        "Cancel": "Cancelar",
                        "Justify": "Justificar",
                        "Inline": "en l\u00ednea",
                        "Copy": "Copiar",
                        "Align left": "Alinear a la izquierda",
                        "Visual aids": "Ayudas visuales",
                        "Lower Greek": "Inferior Griega",
                        "Square": "Cuadrado",
                        "Default": "Por defecto",
                        "Lower Alpha": "Inferior Alfa",
                        "Circle": "C\u00edrculo",
                        "Disc": "Disco",
                        "Upper Alpha": "Superior Alfa",
                        "Upper Roman": "Superior Romana",
                        "Lower Roman": "Inferior Romana",
                        "Name": "Nombre",
                        "Anchor": "Ancla",
                        "You have unsaved changes are you sure you want to navigate away?": "Tiene cambios sin guardar. \u00bfEst\u00e1 seguro de que quiere salir?",
                        "Restore last draft": "Restaurar el \u00faltimo borrador",
                        "Special character": "Car\u00e1cter especial",
                        "Source code": "C\u00f3digo fuente",
                        "B": "B",
                        "R": "R",
                        "G": "G",
                        "Color": "Color",
                        "Right to left": "De derecha a izquierda",
                        "Left to right": "De izquierda a derecha",
                        "Emoticons": "Emoticonos",
                        "Robots": "Robots",
                        "Document properties": "Propiedades del documento",
                        "Title": "T\u00edtulo",
                        "Keywords": "Palabras clave",
                        "Encoding": "Codificaci\u00f3n",
                        "Description": "Descripci\u00f3n",
                        "Author": "Autor",
                        "Fullscreen": "Pantalla completa",
                        "Horizontal line": "L\u00ednea horizontal",
                        "Horizontal space": "Espacio horizontal",
                        "Insert\/edit image": "Insertar\/editar imagen",
                        "General": "General",
                        "Advanced": "Avanzado",
                        "Source": "Enlace",
                        "Border": "Borde",
                        "Constrain proportions": "Restringir proporciones",
                        "Vertical space": "Espacio vertical",
                        "Image description": "Descripci\u00f3n de la imagen",
                        "Style": "Estilo",
                        "Dimensions": "Dimensiones",
                        "Insert image": "Insertar imagen",
                        "Insert date\/time": "Insertar fecha\/hora",
                        "Remove link": "Quitar enlace",
                        "Url": "URL",
                        "Text to display": "Texto para mostrar",
                        "Anchors": "Anclas",
                        "Insert link": "Insertar enlace",
                        "New window": "Nueva ventana",
                        "None": "Ninguno",
                        "The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "El enlace que has introducido no parece ser una enlace externo. Quieres a\u00f1adir el prefijo necesario http:\/\/ ?",
                        "Target": "Destino",
                        "The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "El enlace que has introducido no parece ser una direcci\u00f3n de correo electr\u00f3nico. Quieres a\u00f1adir el prefijo necesario mailto: ?",
                        "Insert\/edit link": "Insertar\/editar enlace",
                        "Insert\/edit video": "Insertar\/editar video",
                        "Poster": "Miniatura",
                        "Alternative source": "Enlace alternativo",
                        "Paste your embed code below:": "Pega tu c\u00f3digo embebido debajo",
                        "Insert video": "Insertar video",
                        "Embed": "Incrustado",
                        "Nonbreaking space": "Espacio fijo",
                        "Page break": "Salto de p\u00e1gina",
                        "Paste as text": "Pegar como texto",
                        "Preview": "Previsualizar",
                        "Print": "Imprimir",
                        "Save": "Guardar",
                        "Could not find the specified string.": "No se encuentra la cadena de texto especificada",
                        "Replace": "Reemplazar",
                        "Next": "Siguiente",
                        "Whole words": "Palabras completas",
                        "Find and replace": "Buscar y reemplazar",
                        "Replace with": "Reemplazar con",
                        "Find": "Buscar",
                        "Replace all": "Reemplazar todo",
                        "Match case": "Coincidencia exacta",
                        "Prev": "Anterior",
                        "Spellcheck": "Corrector ortogr\u00e1fico",
                        "Finish": "Finalizar",
                        "Ignore all": "Ignorar todos",
                        "Ignore": "Ignorar",
                        "Add to Dictionary": "A\u00f1adir al Diccionario",
                        "Insert row before": "Insertar fila antes",
                        "Rows": "Filas",
                        "Height": "Alto",
                        "Paste row after": "Pegar la fila despu\u00e9s",
                        "Alignment": "Alineaci\u00f3n",
                        "Border color": "Color del borde",
                        "Column group": "Grupo de columnas",
                        "Row": "Fila",
                        "Insert column before": "Insertar columna antes",
                        "Split cell": "Dividir celdas",
                        "Cell padding": "Relleno de celda",
                        "Cell spacing": "Espacio entre celdas",
                        "Row type": "Tipo de fila",
                        "Insert table": "Insertar tabla",
                        "Body": "Cuerpo",
                        "Caption": "Subt\u00edtulo",
                        "Footer": "Pie de p\u00e1gina",
                        "Delete row": "Eliminar fila",
                        "Paste row before": "Pegar la fila antes",
                        "Scope": "\u00c1mbito",
                        "Delete table": "Eliminar tabla",
                        "H Align": "Alineamiento Horizontal",
                        "Top": "Arriba",
                        "Header cell": "Celda de la cebecera",
                        "Column": "Columna",
                        "Row group": "Grupo de filas",
                        "Cell": "Celda",
                        "Middle": "Centro",
                        "Cell type": "Tipo de celda",
                        "Copy row": "Copiar fila",
                        "Row properties": "Propiedades de la fila",
                        "Table properties": "Propiedades de la tabla",
                        "Bottom": "Abajo",
                        "V Align": "Alineamiento Vertical",
                        "Header": "Cabecera",
                        "Right": "Derecha",
                        "Insert column after": "Insertar columna despu\u00e9s",
                        "Cols": "Columnas",
                        "Insert row after": "Insertar fila despu\u00e9s ",
                        "Width": "Ancho",
                        "Cell properties": "Propiedades de la celda",
                        "Left": "Izquierda",
                        "Cut row": "Cortar fila",
                        "Delete column": "Eliminar columna",
                        "Center": "Centrado",
                        "Merge cells": "Combinar celdas",
                        "Insert template": "Insertar plantilla",
                        "Templates": "Plantillas",
                        "Background color": "Color de fondo",
                        "Custom...": "Personalizar...",
                        "Custom color": "Color personalizado",
                        "No color": "Sin color",
                        "Text color": "Color del texto",
                        "Show blocks": "Mostrar bloques",
                        "Show invisible characters": "Mostrar caracteres invisibles",
                        "Words: {0}": "Palabras: {0}",
                        "Insert": "Insertar",
                        "File": "Archivo",
                        "Edit": "Editar",
                        "Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "\u00c1rea de texto enriquecido. Pulse ALT-F9 para el menu. Pulse ALT-F10 para la barra de herramientas. Pulse ALT-0 para ayuda",
                        "Tools": "Herramientas",
                        "View": "Ver",
                        "Table": "Tabla",
                        "Format": "Formato"
                    });

                    tinymce.init({
                        selector: '[data-rich-text="' + $(this).attr('data-rich-text') + '"]',
                        menubar: "edit insert view format table tools",
                        plugins: [
                            "autolink link image lists charmap preview hr anchor",
                            "searchreplace wordcount visualchars code insertdatetime media nonbreaking",
                            "table contextmenu emoticons paste textcolor"
                        ]
                    });
                }
            );

        },

        //setSortingOrder = function () {
        //    //console.log("Reorderding");
        //  $("[data-image-order]").each(function(pos) {
        //      $(this).attr("data-image-order", pos);
        //      //console.log("pos: ", pos, this);
        //  });
        //},

        initGallerySort = function () {
            $(".sortable").sortable({
                //stop: setSortingOrder
            });
            $(".sortable").disableSelection();
        },


        _getDataTableType = function () {
            return $('[data-datatable]').attr('data-datatable-type');
        },

        initDataTables = function () {
            var type = _getDataTableType(),
                defaultOptions = {

                    // dom: "Blfrtip",

                    dom: "<'row'<'col-sm-3'l><'col-sm-6 text-center'B><'col-sm-3'f>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-5'i><'col-sm-7'p>>",

                    buttons: [],

                    "pageLength": 50,

                    "language": {
                        "sProcessing": "Procesando...",
                        "sLengthMenu": "Mostrar _MENU_ registros",
                        "sZeroRecords": "No se encontraron resultados",
                        "sEmptyTable": "Ningún dato disponible en esta tabla",
                        "sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                        "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                        "sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
                        "sInfoPostFix": "",
                        "sSearch": "Buscar:",
                        "sUrl": "",
                        "sInfoThousands": ",",
                        "sLoadingRecords": "Cargando...",
                        "oPaginate": {
                            "sFirst": "Primero",
                            "sLast": "Último",
                            "sNext": "Siguiente",
                            "sPrevious": "Anterior"
                        },
                        "oAria": {
                            "sSortAscending": ": Activar para ordenar la columna de manera ascendente",
                            "sSortDescending": ": Activar para ordenar la columna de manera descendente"
                        }
                    },

                    "order": []

                };


            switch (type) {
                default:


                    return _initDefaultTable(defaultOptions);
                    break;
            }

        },

        _initDefaultTable = function (options) {

            var $datatables = $('[data-datatable]'),
                tables = $datatables.DataTable(options);
            return tables;
        },

        initAjaxButtons = function () {

            var $buttons = $('[data-ajax-method]').ajaxButton({dispatcher: AluminiosVallirana.dispatcher});

            AluminiosVallirana.dispatcher.addResponseProcessor('deactivate_user', function (data) {
                var tables = AluminiosVallirana.tables,
                    $icons,
                    $item;
                $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                tables.showIcon($icons);
                tables.moveRow('Activados', 'Desactivados', data.item.id, 'users_');
            })
                .addResponseProcessor('deleted_confirmed_user', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Desactivados', data.item.user_id || data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'users_');
                })
                .addResponseProcessor('deleted_unconfirmed_user', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Sin_Validar', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'users_');

                })
                .addResponseProcessor('reactivated_confirmed_user', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                    tables.removeIcon($icons);

                    tables.moveRow('Desactivados', 'Activados', data.item.id, 'users_');
                })
                .addResponseProcessor('reactivated_unconfirmed_user', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                    tables.removeIcon($icons);

                    tables.moveRow('Sin_Validar', 'Activados', data.item.id, 'users_');
                })
                .addResponseProcessor('reactivated_banner', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                    tables.removeIcon($icons);


                    tables.moveRow('Desactivados', 'Activados', data.item.id, 'banners_');
                })
                .addResponseProcessor('deleted_banner', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Desactivados', data.item.id, 'banners_');
                    tables.deleteRow('Todos', data.item.id, 'banners_');

                })
                .addResponseProcessor('reactivated_profile', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                    tables.removeIcon($icons);


                    tables.moveRow('Desactivados', 'Activados', data.item.id, 'profiles_');
                })
                .addResponseProcessor('deactivated_profile', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="reactivate"]'));
                    tables.showIcon($icons);


                    tables.moveRow('Activados', 'Desactivados', data.item.id, 'profiles_');
                })
                .addResponseProcessor('deleted_profile', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    if (data.item.active) {
                        tables.deleteRow('Activados', data.item.id, 'profiles_');
                    } else {
                        tables.deleteRow('Desactivados', data.item.id, 'profiles_');
                    }

                    tables.deleteRow('Sin_Revisar', data.item.id, 'profiles_');
                    tables.deleteRow('Todos', data.item.id, 'profiles_');

                })
                .addResponseProcessor('verified_profile', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Sin_Revisar', data.item.id, 'profiles_');
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="verify"]'));
                    tables.removeIcon($icons);
                })
                .addResponseProcessor('deleted_reward', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Pendientes', data.item.id, 'rewards_');
                    tables.deleteRow('Todas', data.item.id, 'rewards_');
                })
                .addResponseProcessor('confirmed_reward', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.moveRow('Pendientes', 'Confirmadas', data.item.id, 'rewards_');
                    $item = $('[data-item-id=' + data.item.id + ']');

                    $icons = $($item.find('[data-ajax-method]'));
                    tables.removeIcon($icons);
                })
                .addResponseProcessor('remove_certification', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('profiles_certification', data.item.id, '');
                })
                .addResponseProcessor('deleted_comercial', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'comerciales_');
                })
                .addResponseProcessor('deleted_client', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'clients_');
                })
                .addResponseProcessor('deleted_componente', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'componentes_');
                })

                .addResponseProcessor('show_edit_componente_dialog', function (data) {
                    var $view = $(data.item.view);
                    $view.modal(true);

                    // TODO: Boton confirmar es ajax, el cancel debe destruir el dialogo (se crea uno nueva cada vez, así que no hay problema)

                    // ALERTA! el botón para confirmar debe enviar el formulario
                    var $button = $view.find('input[type="submit"]');


                    $button.ajaxButton({dispatcher: AluminiosVallirana.dispatcher, submit: true});

                    $button.on('click', function () {
                        $view.modal('hide');
                    });

                    $view.on('hidden.bs.modal', function (e) {
                        $view.remove();
                    });


                })

                .addResponseProcessor('updated_componente', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');

                    // TODO: pasar los datos como un array con el siguiente formato:
                    // referencia, nombre, precio tarifa, precio coste, precio venta.

                    var newRow = [
                        data.item.referencia,
                        data.item.nombre,
                        data.item.precioTarifa + "€",
                        data.item.precioCoste + "€",
                        data.item.precioVenta + "€"
                    ];
                    tables.updateRow('Todos', data.item.id, 'componentes_', newRow);

                    // TODO: Comprobar si al updatear la fila continuan funcionando los botones ajax o hay que volver a añadirlos

                })
                .addResponseProcessor('deleted_color', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'colores_');
                })
                .addResponseProcessor('deleted_suplemento', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    // tables.deleteRow('Todos', data.item.id, 'users_');
                    tables.deleteRow('Todos', data.item.id, 'suplementos_');
                })
                .addResponseProcessor('show_element_dialog', function (data) {
                    AluminiosVallirana.presupuesto.mostrarElemento(data);
                })
                .addResponseProcessor('edit_linea_dialog', function (data) {
                    AluminiosVallirana.presupuesto.editarLinea(data);
                })
                .addResponseProcessor('deleted_linea', function (data) {
                    AluminiosVallirana.presupuesto.eliminarLinea(data.item.id);
                })
                .addResponseProcessor('validated_unconfirmed_presupuesto', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="validate"]'));
                    tables.removeIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="delete"]'));
                    tables.removeIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="cancel"]'));
                    tables.showIcon($icons);


                    tables.moveRow('Sin_Confirmar', 'Validados', data.item.id, 'presupuestos_');
                })
                .addResponseProcessor('validated_confirmed_presupuesto', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="validate"]'));
                    tables.removeIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="delete"]'));
                    tables.removeIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="cancel"]'));
                    tables.showIcon($icons);

                    tables.moveRow('Confirmados', 'Validados', data.item.id, 'presupuestos_');
                })
                .addResponseProcessor('deleted_presupuesto', function (data) {
                    console.log("Response: deleted_presupuesto", data);
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Sin_Confirmar', data.item.id, 'presupuestos_');
                    tables.deleteRow('Todos', data.item.id, 'presupuestos_');
                    console.log("*** llamados a los dos deleteRow");
                })
                .addResponseProcessor('cancel_presupuesto_confirmado', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;

                    tables.moveRow('Confirmados', 'Sin_Confirmar', data.item.id, 'presupuestos_');

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="validate"]'));
                    tables.showIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="delete"]'));
                    tables.showIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="cancel"]'));
                    tables.removeIcon($icons);


                })

                .addResponseProcessor('cancel_presupuesto_validado', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.moveRow('Validados', 'Sin_Confirmar', data.item.id, 'presupuestos_');

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="validate"]'));
                    tables.showIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="delete"]'));
                    tables.showIcon($icons);

                    $icons = $($('[data-item-id=' + data.item.id + ']').find('[data-icon="cancel"]'));
                    tables.removeIcon($icons);
                })

                .addResponseProcessor('show_selector_dialog', function (data) {
                    AluminiosVallirana.presupuesto.mostrarSelector(data);
                })

                .addResponseProcessor('revoked_user', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;
                    tables.deleteRow('Desactivados', data.item.user_id, 'users_');
                    tables.deleteRow('Todos', data.item.user_id, 'users_');
                    tables.deleteRow('Activados', data.item.user_id, 'users_');
                    tables.deleteRow('Sin_Validar', data.item.user_id, 'users_');

                })
                .addResponseProcessor('revoked_email', function (data) {
                    var tables = AluminiosVallirana.tables,
                        $icons,
                        $item;

                    var newRow = [
                        data.item.email,
                        data.item.user_id || '',
                        data.item.reason,
                        data.item.version,
                        data.item.created_at,
                        data.item.ip,
                        ''
                    ];


                    var extraClass = '';
                    if (data.item.reason = '**Revocado**') {
                        extraClass = 'noconsent'
                    }

                    tables.addRow('Todos', data.item.id, 'consents_', newRow, extraClass);
                    tables.addRow('Revocados', data.item.id, 'consents_', newRow, extraClass);
                })
        },

        initButtons = function () {
            // $('[data-presupuesto-cliente-url]').on('click', function (e) {
            //     var $this = $(this);
            //
            //     var recargo;
            //     var url = $this.attr('data-presupuesto-cliente-url');
            //
            //
            //     do {
            //         recargo = prompt("Introduce el recargo a aplicar (por ejemplo 30 para aplicar un 30%):", $('#recargo').val());
            //
            //         if (isNaN(recargo )) {
            //             alert("El recargo debe ser un número");
            //         }
            //     } while (isNaN(recargo));
            //
            //
            //     // console.log("Recargo entrado:", recargo);
            //
            //     if (recargo === null) {
            //         recargo = 0;
            //     }
            //
            //     url += '/' + recargo;
            //
            //
            //     if ($this.attr('data-custom-param')) {
            //
            //         var descuento;
            //
            //         do {
            //             descuento = prompt("Introduce el descuento a aplicar (por ejemplo 3 para aplicar un 3%):", "3");
            //
            //             if (isNaN(descuento)) {
            //                 alert("El descuento debe ser un número");
            //             }
            //         } while (isNaN(descuento));
            //
            //         //console.log("Recargo entrado:", recargo);
            //
            //         if (descuento !== null) {
            //             url += '?descuento=' + descuento;
            //         }
            //
            //     }
            //
            //     $this.attr('href', url);
            // });

            // ALERTA: Desactivado, esto es para pedir el % de descuento a mostrar en el presupuesto del usuario
            // $('[data-presupuesto-usuario-url]').on('click', function (e) {
            //
            //     var $this = $(this);
            //
            //     if (!$this.attr('data-custom-param')) {
            //         return;
            //     }
            //
            //     var descuento;
            //
            //     do {
            //         descuento = prompt("Introduce el descuento a aplicar (por ejemplo 3 para aplicar un 3%):", "3");
            //
            //         if (isNaN(descuento)) {
            //             alert("El descuento debe ser un número");
            //         }
            //     } while (isNaN(descuento));
            //
            //     var url = $this.attr('data-presupuesto-usuario-url');
            //
            //     //console.log("Recargo entrado:", recargo);
            //
            //     if (descuento !== null) {
            //         url += '?descuento=' + descuento;
            //     }
            //
            //     $this.attr('href', url);
            // });

        };


    (function () {
        initDataCounters();
        initSlug();
        initVideo();
        initMasterTitleSync();
        initRichTextEditors();
        initGallerySort();
        initAjaxButtons();
        initButtons();

        var tables = initDataTables();
        AluminiosVallirana.tables.init(tables);


        // Inicializació del buscador de agenda
        $('.js-data-example-ajax').select2({
            selectOnClose: true,
            placeholder: "Selecciona un cliente de la agenda",
            width: 'resolve',
            dropdownAutoWidth : true,
            ajax: {
                // url: 'https://api.github.com/search/repositories',
                url: '/clients/list',
                dataType: 'json',
                delay: 250,
                data: function (params) {

                    // console.log(params);
                    return {
                        term: params.term
                    }
                },
                processResults: function (data) {
                    // console.log(data);
                    // Transforms the top-level key of the response object from 'items' to 'results'
                    return {
                        results: data.items
                    };

                }
                // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
            }
        });

        var $clientSelect = $('#selectClient');
        var $clientDropdown = $('#addClientDropdown');
        var $clientCollapse = $('#clientCollapse');

        $clientCollapse.on('shown.bs.collapse', function () {
            $clientSelect.prop('disabled', true);

            $clientCollapse.find('[name="_nif"]').prop('required', true);
            $clientCollapse.find('[name="_nombre_fiscal"]').prop('required', true);

        });

        $clientCollapse.on('hidden.bs.collapse', function () {
            $clientSelect.prop('disabled', false);

            $clientCollapse.find('[name="_nif"]').prop('required', false);
            $clientCollapse.find('[name="_nombre_fiscal"]').prop('required', false);
        });


    }());


});