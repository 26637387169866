/**
 * Código para realizar las llamadas AJAX
 *
 * @author Xavier García <xaviergaro.dev@gmail.com>
 */
$(document).ready(function () {
        // Código para la subida de imágenes asyncrona

        var $gallery = $('#gallery-container'),

            /**
             * Objecto para gestionar la barra de progreso.
             *
             *
             * @type {{$bar: (*|jQuery|HTMLElement), init: Function, update: Function}}
             */
            progressBar = {

                $bar: $('div.progress-bar'),

                init: function () {

                    this.$bar.addClass('active');
                    this.$bar.removeClass('progress-bar-danger');
                    this.$bar.css('width', '0');

                },

                update: function (percent) {
                    var oldValue = parseFloat(this.$bar.attr('aria-valuenow')).toFixed(2);
                    percent = parseFloat(percent).toFixed(2);

                    if (oldValue === percent && percent > 99) {
                        this.$bar.css('width', '100%');
                        this.$bar.attr({'aria-valuenow': 100});
                        this.$bar.find('span').text('Procesando');
                    } else {
                        this.$bar.css('width', percent + '%');
                        this.$bar.attr({'aria-valuenow': percent});
                        this.$bar.find('span').text(percent + " %");
                    }


                },

                disable: function () {

                    this.$bar.removeClass('active');
                    this.$bar.css('width', '100%');
                    this.$bar.attr({'aria-valuenow': 100});
                    this.$bar.find('span').text('Completo');

                },

                error: function () {

                    this.$bar.addClass('progress-bar-danger');
                    this.$bar.removeClass('active');
                    this.$bar.find('span').text('Error');

                }
            },

            /**
             * Añade una imágen a la galería
             *
             * @param image
             */
            addImageToGallery = function (image) {

                var $image = $(image);

                $image.appendTo($gallery);
                //$image.prependTo($gallery);
                addDeleteHandler($image.find('a.delete'));

                return $image;
            },

            removeImageFromGallery = function (id) {
                $('[data-image-id=' + id + ']').remove();
                //$('img[src="' + fileUrl + '"]').parent().parent().remove()

            },

            /**
             * Añade un mensaje al div de alertas con el estilo Bootstrap indicado en el tipo
             *
             * @param message
             * @param type
             */
            showMessage = function (message, type) {

                var classes = "alert";

                if (!type) {
                    type = 'info'
                }

                switch (type) {
                    case 'info':
                        classes += " alert-info";
                        break;

                    case 'error':
                        classes += " alert-danger";
                        break;
                }

                $('#alert').html(
                    '<div class="' + classes + '">'
                    + '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>'
                    + message + '</div>'
                );

            },

            progressHandlingFunction = function (e) {

                var percent;

                if (e.lengthComputable) {
                    percent = 1 / e.total * e.loaded * 100;
                    progressBar.update(percent)
                }

            },

            errorHandler = function (xhr, status, error) {

                console.log(xhr.responseText);
                progressBar.error();
                showMessage(error, 'error');

            },

            addDeleteHandler = function ($item) {

                $item.click(function (e) {

                    var completeHandler = function (data) {

                            //console.log(data);
                            removeImageFromGallery(data.id);
                            // console.log("id del elemento borrado: ", data.id);
                            // console.log("Current featured: ", getCurrentFeatured());


                            if (!getCurrentFeatured()) {
                                setFeatured();
                            }

                            showMessage(data.message);
                            progressBar.disable();

                        },


                        token = $("input[name='_token']").attr('value');
                    // console.log("token:", token);

                    e.preventDefault();

                    progressBar.init();

                    $.ajax({
                        url: $(this).attr('href'),
                        type: 'DELETE',

                        // Ajax events
                        beforeSend: null,
                        success: completeHandler,
                        error: errorHandler,

                        // Form data
                        data: token, // ESto se envía como parámetro

                        // Options to tell jQuery not to process data or worry about content-type
                        cache: false,
                        contentType: false,
                        processData: false,

                        headers: {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },

                        xhr: function () { // Custom XMLHttpRequest

                            var myXhr = $.ajaxSettings.xhr();

                            if (myXhr.upload) {
                                myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // For handling progress of the upload
                            }

                            return myXhr;

                        }
                    });
                });
            };


        // Selector para detectar si se produce un cambio en un botón de selección de archivos
        $(':file[data-upload-ajax]').change(function () {
            // console.log("#:file[data-up`load-ajax")

            var formData = new FormData($('form#image-uploader')[0]),

                completeHandler = function (data) {
                    var $image;

                    for (var i = 0, len = data.entries.length; i < len; i++) {
                        $image = addImageToGallery(data.entries[i].view);
                        addFavoriteOnHandler($image.find('.favorite-on'));
                        addFavoriteOffHandler($image.find('.favorite-off'));

                        //console.log("CurrentFeatured: ", getCurrentFeatured());


                        if (!getCurrentFeatured() && i == len - 1) {
                            setFeatured();
                            //console.log("Se ha llamado a setFeatured");
                        } else {

                        }
                    }

                    showMessage(data.message);
                    progressBar.disable();

                };

            progressBar.init();


            $.ajax({

                url: $('form#image-uploader').attr('action'),
                type: 'POST',

                // Ajax events
                beforeSend: null,
                success: completeHandler,
                error: errorHandler,

                // Form data
                data: formData,

                // Options to tell jQuery not to process data or worry about content-type
                cache: false,
                contentType: false,
                processData: false,

                xhr: function () { // Custom XMLHttpRequest

                    var myXhr = $.ajaxSettings.xhr();

                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // For handling progress of the upload
                    }

                    return myXhr;

                }
            });
        });

        $('a.delete').each(function () {

            addDeleteHandler($(this));

        });

        $('#main-form').submit(function (e) {

            var total = [];


            $('figure[data-image-id]').each(function () {
                var id = $(this).attr('data-image-id');
                total.push(id);
            });

            var input = $("<input>")
                .attr("type", "hidden")
                .attr("name", "images-id[]").val(total);
            $('#main-form').append($(input));

        });


        var addFavoriteOnHandler = function ($item) {

                $item.click(function (e) {
                    e.preventDefault();
                });
            },

            addFavoriteOffHandler = function ($item) {

                $item.click(function (e) {

                    e.preventDefault();

                    setFeatured($(this).closest('figure[data-image-id]').attr('data-image-id'));
                });

            };


        // Esto no hace nada, siempre tiene que haber al menos 1
        $('.favorite-on').each(function () {

            addFavoriteOnHandler($(this));

        });

        // añadimos listener a los activos
        $('.favorite-off').each(function () {

            addFavoriteOffHandler($(this));

        });

        var $featuredField = $('input[name="featured_id"]'),

            getCurrentFeatured = function () {
                var id = $featuredField.val(), $figure = $('figure[data-image-id="' + id + '"]')

                if ($figure.length === 0) {

                    return null;
                }

                return $featuredField.val();

            },

            setFeatured = function (id) {

                if (!id) {
                    id = $('figure[data-image-id]').first().attr('data-image-id');
                }

                unsetFeatured();
                $featuredField.val(id);
                getFavoriteButtonsById(id);

                var buttons = getFavoriteButtonsById(id);
                buttons.$on.removeClass('hidden');
                buttons.$off.addClass('hidden');

            },

            unsetFeatured = function () {
                var buttons = getFavoriteButtonsById(getCurrentFeatured());

                buttons.$on.addClass('hidden');
                buttons.$off.removeClass('hidden');
            },

            getFavoriteButtonsById = function (id) {

                var $figure = $('figure[data-image-id="' + id + '"]');

                return {
                    $on: $figure.find('.favorite-on'),
                    $off: $figure.find('.favorite-off')
                };
            };


        // ALERTA: Esto es para gestionar solo el upload del logo
        $('#logo-uploader :file').change(function () {
            // console.log("#logo-uploader :file")

            var formData = new FormData($('form#logo-uploader')[0]),
                $logo = $('img#logo-preview'),
                $boton = $('a#upload-btn'),

                singleImage = function (data) {
                    var $dest = $('input[data-image-source="' + data.entry['img-destination'] + '"]');

                    $dest.val(window.location.hostname + data.entry['url']);
                    $dest.trigger('change');

                    $('#upload-dialog').modal('hide');
                    $('[data-hide-after-upload]').css('display', 'none');
                    $logo.attr('src', data.entry['url']);
                    $boton.html('Subir nuevo logo');

                },

                completeHandler = function (data) {
                    //console.log("Información retornada al cargar las imágenes");
                    //console.log(data);
                    //console.log("**********************");

                    singleImage(data);

                    showMessage(data.message);
                    progressBar.disable();

                };


            progressBar.init();


            $.ajax({

                url: $('form#logo-uploader').attr('action'),
                type: 'POST',

                // Ajax events
                beforeSend: function () {
                    // console.log("Enviant formData:", formData);
                },
                success: completeHandler,
                error: errorHandler,

                // Form data
                data: formData,

                // Options to tell jQuery not to process data or worry about content-type
                cache: false,
                contentType: false,
                processData: false,

                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },

                xhr: function () { // Custom XMLHttpRequest

                    var myXhr = $.ajaxSettings.xhr();

                    if (myXhr.upload) {
                        myXhr.upload.addEventListener('progress', progressHandlingFunction, false); // For handling progress of the upload
                    }

                    return myXhr;

                }
            });
        });


        // Inicializamos el favorito con el campo del formulario
        setFeatured(getCurrentFeatured());

        $(document).ajaxError(function (event, request, settings) {
            // console.error(event, request, settings);
            switch (request.status) {
                case 500:
                    alert("Error 500: se ha producido un error en el servidor");
                    break;
                case 404:
                    alert("Error 404: no se ha encontrado el recurso");
                    break;

                case 403:
                    alert("Error 403: no tienes autorización para acceder al recurso");
                    break;

                default:
                    // console.warn("Error " + request.status+ ": se ha producido un error al enviar la petición");
                    // alert("Error " + request.status+ ": se ha producido un error al enviar la petición");
            }


        });
    }
);