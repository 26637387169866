$(document).ready(function () {
    var $panelCookies = $('.cookies-control');
    var controlcookies = function () {
        localStorage.controlcookie = (localStorage.controlcookie || 0);

        localStorage.controlcookie++;
        $panelCookies.css('display', 'none');

    };

    if (localStorage.controlcookie > 0) {
        $panelCookies.css('display', 'none');
    }

    $panelCookies.find('.close').on('click', controlcookies);
});

