// Esperamos a cargar completamente la página antes de realizar las llamadas al jQuery
$(document).ready(function () {

    // Nos aseguramos de colocar al final de la página
    var footerAtBottom = function () {
        var footHeight = parseInt($('footer').css('height').replace('px', '')),
            headHeight = parseInt($('header .navbar').css('height').replace('px', '')),
            mainHeight = parseInt($('main').css('height').replace('px', '')),
            h = Math.max(document.documentElement.clientHeight, Window.innerHeight || 0);

        if (footHeight + headHeight + mainHeight < h) {
            $('main').css('height', h - (footHeight + headHeight));
        }
    };


    // Código para hacer que el menú colapsable inferior se desplace hacia arriba
    $(function () {
        $('#footer-navbar.collapse').on('show.bs.collapse', function () {
            $("html, body").animate({scrollTop: $(document).height()}, "slow");
        });
    });

    // Código para el zoom de las imagenes de item-box
    var selector = '.item-box img';

    $(selector).hover(function () {
        $(this).addClass('transition');

    }, function () {
        $(this).removeClass('transition');
    });


    var changeHeight = function () {
        //console.log("cambiando height", this);
        var h = 0;

        $('.auto-row-height').each(function () {
            //console.log("current-height", $(this).outerHeight());
            $(this).css({'height': 'auto'});


            if ($(this).outerHeight() > h) {
                h = $(this).outerHeight();
            }

            $(this).css({'height': h});
        });
    };


    $(window).resize(function () {
        changeHeight();
        footerAtBottom();
    });


    // Galería

    $.extend(true, $.magnificPopup.defaults, {
        tClose: 'Cerrar (Esc)',
        tLoading: 'Cargando...',
        gallery: {
            tPrev: 'Anterior (Flecha izquierda)',
            tNext: 'Próxima (Flecha derecha)',
            tCounter: '%curr% de %total%'
        },
        image: {
            tError: '<a href="%url%">La imagen</a> no ha podido cargarse.'
        },
        ajax: {
            tError: '<a href="%url%">El contenido</a> no ha podido cargarse.'
        }
    });


    $('figure>a').magnificPopup({
        degelate: 'a',
        type: 'image',
        gallery: {
            enabled: true,

            preload: [0, 2]
        },
        mainClass: 'mfp-with-zoom,',
        zoom: {
            enabled: true,
            duration: 300,
            easing: 'ease-in-out',

            opener: function (openerElement) {
                return openerElement.is('img') ? opernerElement : openerElement.find('img');
            }
        }
    });

    // Incialització
    (function () {
        changeHeight();
    })();

    document.oncontextmenu = function(e) {
        e = e || window.event;
        if (/^img$/i.test((e.target || e.srcElement).nodeName)){
            alert("No está permitido descargar imágenes de esta página.\n\nContáctanos en info@aluminiosvallirana.es si necesitas más información.\n\nGracias");
            return false;
        }
    };

    $('#contact-upload').on('change', function (){
        var contactUpload, file, totalSize, i, MAX_FILE_UPLOAD=26214400;
        if (!window.FileReader) {
            //console.log("The file API isn't supported on this browser yet.");
            return;
        }

        contactUpload = $(this).get(0);

        if (contactUpload && contactUpload.files && contactUpload.files[0]) {
            totalSize = 0;
            for (i = 0; i < contactUpload.files.length; i++) {
                file = contactUpload.files[i];
                //console.log("File " + file.name + " is " + file.size + " bytes in size");
                totalSize += file.size;
            }

            //console.log("Total file size: ", totalSize);
            if (totalSize>MAX_FILE_UPLOAD) {
                contactUpload.value=[];
                alert("El tamaño máximo de los archivos a añadir es "+ Math.round(MAX_FILE_UPLOAD/1024/1024*100)/100+" MB, pero los archivos seleccionados tienen un tamaño de " + Math.round(totalSize/1024/1024*100)/100+ " MB.\n\nPor favor, reduce el tamaño de las imágenes o comprime los archivos y vuelve a intentarlo.\n\nGracias.")
            }
        }

    });

});



