if (!AluminiosVallirana) {
    var AluminiosVallirana = {};
}

AluminiosVallirana.dispatcher = {

    _responseProcessors: {},

    addResponseProcessor: function (action, processor) {
        // console.log("Añadido processor para:", action);
        this._responseProcessors[action] = processor;
        return this;
    },

    process: function (data) {
        // console.log("Processing: ", data);
        if (this._responseProcessors[data.action]) {
            this._responseProcessors[data.action](data);
        } else {
            console.warn("No existe ningún processor para ", data.action);
        }
    }
};

(function ($) {
    $.fn.ajaxButton = function (options) {

        // console.log("ajax button", options);

        if (!options.dispatcher) {
            // console.log("options:", options);
            throw new Error("Debe pasarse un dispatcher como opción de AjaxButton");
        }
        // funciones y variables privadas

        $(this).off('click'); // Desactivamos cualquier otro evento click vinculado a este boton;

        if (options.submit) {

            this.click(function () {

                console.log("Ajax method: form");

                var $this = $(this),
                    $form = $('#' + $this.attr('form')),
                    method = $form.attr('method'),
                    url = $this.attr('href') || $form.attr('action') || $this.attr('action'),
                    token = $('meta[name="csrf-token"]').attr('content'),
                    callback = options.dispatcher.process;

                var confirmMessage = $this.attr('data-confirm') || '';

                if (confirmMessage.length>0 && !confirm(confirmMessage)) {

                    return false;
                }

                $.ajax({
                    method: method,
                    url: url,
                    data: $form.serialize(),
                    //             data: "someData=someThing&someMore=somethingElse",
                    // success: callback.bind(this),
                    success: callback.bind(options.dispatcher), // Se debe pasar como context el dispatcher
                    statusCode: {
                        500: function() {
                            alert("Error 500: no se ha podido resolver la petición");
                        },
                        404: function() {
                            alert("Error 404: no se ha encontrado el destino");
                        },
                        403: function() {
                            alert("Error 403: no tienes autorización para acceder al recurso");
                        }
                    },
                    headers: {
                        'X-CSRF-TOKEN': token
                    }
                });
                return false; // stop the browser following the link
            });


        } else {

            this.click(function () {
                // console.log("ajax-method: button");
                var $this = $(this),
                    method = $this.attr('data-ajax-method'),
                    url = $this.attr('href'),
                    token = $('meta[name="csrf-token"]').attr('content'),
                    callback = options.dispatcher.process;

                var confirmMessage = $this.attr('data-confirm') || '';

                if (confirmMessage.length>0 && !confirm(confirmMessage)) {
                    return false;
                }


                $.ajax({
                    method: method,
                    url: url,
                    data: token,
                    //             data: "someData=someThing&someMore=somethingElse",
                    // success: callback.bind(this),
                    success: callback.bind(options.dispatcher), // Se debe pasar como context el dispatcher

                    headers: {
                        'X-CSRF-TOKEN': token
                    }
                });
                return false; // stop the browser following the link

            });
        }


    }
})(jQuery);